
import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Typography,
  Collapse,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  FormHelperText,
  Box,
  Stack,
  ListItemText,
} from "@mui/material";
import { DELIVERY_AGENT_ID, SAVE_INTEGRATION_DELIVERY_AGENT } from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useState } from "react";
import FormButton from "../../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../../HOC/CustomFunctions/setValidationError";
import {
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_CANCELLATION_REASONS_DROPDOWN,
  LIST_ZONES_DROPDOWN
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../../Layout/TitleAppBar";
import EnumDropdown from "../../HOC/CustomComponents/EnumDropdown"
import ServiceTable from "./integrationTable";
import { pushUrl } from "../../HOC/CustomFunctions/pushUrl";
import IntegrationZoneTable from "./integrationZoneTable";
import MuiSwitch from "../../HOC/MUI/MUIswitch";
import CustomTab from "../../HOC/FunctionComponents/CustomTab";


const PREFIX = 'DeliveryAgentIntegrationForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
  title: `${PREFIX}-title`,

};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));



const DeliveryAgentIntegrationForm = (props) => {
  const [serviceList, setServiceList] = useState([]);
  const deliveryAgentId = parseInt(props.match.params.id);

  const [serviceIndex, setServiceIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [serviceErrorMessage, setServiceErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    service: "",
  });
  const handelCloseDialog = () => {
    setDialog(false);
    setServiceErrorMessage(false);
    setServiceIndex((prev) => ({ ...prev, update: false }));
  };

  const addServiceDialog = (index) => {
    serviceReset();
    if (index || index === 0) {
      setServiceIndex({
        index,
        update: true,
      });
    } else {
      setServiceIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setServiceErrorMessage(false);
  };


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } =
    useForm({
      defaultValues: {
        active: true,
        deliveryAgentId: deliveryAgentId,
      },
    });

  const {
    control: serviceControl,
    formState: { errors: serviceErrors },
    handleSubmit: serviceHandleSubmit,
    setValue: serviceSetValue,
    watch: serviceWatch,
    reset: serviceReset,
  } = useForm({
    defaultValues: {
      serviceId: "",
      remoteId: "",
    },
  });


  const [cancellationReasonList, setCancellationReasonList] = useState([]);

  const [cancellationReasonIndex, setCancellationReasonIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialogCancel, setDialogCancel] = useState(false);
  const [reasonsErrorMessage, setReasonErrorMessage] = useState(false);
  const [reasonNames, setReasonNames] = useState({
    cancelReason: "",
  });

  const handelCloseDialogReason = () => {
    setDialogCancel(false);
    setReasonErrorMessage(false);
    setCancellationReasonIndex((prev) => ({ ...prev, update: false }));
  };

  const addServiceDialogReason = (index) => {
    cancellationReasonReset();
    if (index || index === 0) {
      setCancellationReasonIndex({
        index,
        update: true,
      });
    } else {
      setCancellationReasonIndex({
        index,
        update: false,
      });
    }
    setDialogCancel(true);
  };

  const onChangeNamesReason = (e, parameter, remove) => {
    const name = e.name;
    setReasonNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setCancellationReasonIndex(false);
  };
  const {
    control: cancellationReasonControl,
    formState: { errors: cancellationReasonErrors },
    handleSubmit: cancellationReasonHandleSubmit,
    setValue: cancellationReasonSetValue,
    watch: cancellationReasonWatch,
    reset: cancellationReasonReset,
  } = useForm({
    defaultValues: {
      cancellationReasonId: "",
      remoteId: "",
    },
  });
  const {
    control: subZoneControl,
    formState: { errors: subZoneErrors },
    handleSubmit: subZoneHandleSubmit,
    setValue: subZoneSetValue,
    watch: subZoneWatch,
  } = useForm({
    defaultValues: {
    },
  });

  const [subZoneList, setSubZoneList] = useState([]);

  const [subZoneIndex, setSubZoneIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialogSubZone, setDialogSubZone] = useState(false);
  const [subZoneId, setSubZoneId] = useState();
  const handelCloseDialogSubZone = () => {
    setDialogSubZone(false);
    setSubZoneIndex((prev) => ({ ...prev, update: false }));
  };

  const addServiceDialogSubZone = (data) => {
    // if (index || index === 0) {
    //   setSubZoneIndex({
    //     index,
    //     update: true,
    //   });
    // } else {
    //   setSubZoneIndex({
    //     index,
    //     update: false,
    //   });
    // }
    setSubZoneId(data)
    setDialogSubZone(true);
  };




  const { errors } = formState;

  const parseData = (data) => {
    return data;
  };

  const onSubmitService = (data) => {
    const newService = {
      service: {
        id: data.serviceId,
        username: selectedNames.service,
      },
      remoteId: data.remoteId,
    };
    const updateService = [...serviceList];
    const invalidService = updateService.some((i, index) =>
      serviceIndex.update && index === serviceIndex.index
        ? false
        : i.service.id === newService.service.id
    );

    if (invalidService) {
      setServiceErrorMessage(true);
    } else {
      if (serviceIndex.update) {
        updateService[serviceIndex.index] = {
          ...updateService[serviceIndex.index],
          ...newService,
        };
        setServiceIndex({ index: serviceIndex.index, update: false });
      } else {
        updateService.push(newService);
        setServiceIndex({
          index: 0,
          update: false,
        });
      }
      setServiceList(updateService);
      handelCloseDialog();
    }
  };

  const onSubmitReason = (data) => {
    const newCancelReason = {
      service: {
        id: data.cancellationReasonId,
        username: reasonNames.cancelReason,
      },
      remoteId: data.remoteId,
    };
    const updateCancelReason = [...cancellationReasonList];
    const invalidCancelReason = updateCancelReason.some((i, index) =>
      cancellationReasonIndex.update && index === cancellationReasonIndex.index
        ? false
        : i.service.id === newCancelReason.service.id
    );

    if (invalidCancelReason) {
      setReasonErrorMessage(true);
    } else {
      if (cancellationReasonIndex.update) {
        updateCancelReason[cancellationReasonIndex.index] = {
          ...updateCancelReason[cancellationReasonIndex.index],
          ...newCancelReason,
        };
        setCancellationReasonIndex({ index: cancellationReasonIndex.index, update: false });
      } else {
        updateCancelReason.push(newCancelReason);
        setCancellationReasonIndex({
          index: 0,
          update: false,
        });
      }
      setCancellationReasonList(updateCancelReason);
      handelCloseDialogReason();
    }
  };
  const onSubmitSubZone = (data) => {

    const zones = []
    var zone
    for (const key in data) {
      if (data[key] === '') {
        delete data[key];
      }
    }
    for (const key in data) {

      if (key.includes('zone')) {
        zone = {
          parent: key.includes('sub') ? false : true,
          remoteId: data[key],
          localId: parseInt(key.match(/\d+/)[0], 10)
        }
        zones.push(zone)
        delete data[key];
      }
    }

    setSubZoneList(zones)
    handelCloseDialogSubZone();
  };
  const [saveIntegration, { loading: saveIntegrationLoading }] = useMutation(
    gql`
      ${SAVE_INTEGRATION_DELIVERY_AGENT.query}
    `
  );


  const { data: updateDeliveryAgent, loading: DelivaryAgentLoadingId } = useQuery(
    gql`
      ${DELIVERY_AGENT_ID.query}
    `,

    {
      skip: !deliveryAgentId,
      variables: { id: deliveryAgentId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const deliveryAgentData = data.deliveryAgent.integration;
        console.log(deliveryAgentData);
        let arr = []
        deliveryAgentData.services.map((e) => {
          let obj = {
            service: '',
            remoteId: ''
          }
          obj.remoteId = e.remoteId
          obj.service = e.localSevice
          arr.push(obj)
        })
        setServiceList(arr)

        let arr2 = []
        deliveryAgentData.cancellationReasons?.map((e) => {
          let obj = {
            service: '',
            remoteId: ''
          }
          obj.remoteId = e.remoteId
          obj.service = e.localCancellationReason
          arr2.push(obj)
        })
        let arr3 = []
        deliveryAgentData.zones?.map((e) => {
          if (e.parent === true) {
            setValue(`zone${e.localZone.id}`, e.remoteId)
          }
          else {
            let obj = {
              parent: false,
              remoteId: '',
              localId: ""
            }
            obj.remoteId = e.remoteId
            obj.localId = e.localZone.id
            obj.parent = e.parent
            arr3.push(obj)
            subZoneSetValue(`subzone${e.localZone.id}`, e.remoteId)

          }

        })
        setSubZoneList(arr3)
        setCancellationReasonList(arr2)
        console.log("active");
        console.log(deliveryAgentData);
        setValue("active", deliveryAgentData.active)
        setValue("useShipmentCode", deliveryAgentData?.useShipmentCode)
        setValue("useSenderData", deliveryAgentData?.useSenderData)
        setValue("token",deliveryAgentData?.token)
        setValue("integrationUrl", deliveryAgentData?.integrationUrl)
        setValue("deliveryAgentId", deliveryAgentData?.deliveryAgent?.id)
        setValue("id", deliveryAgentData?.id)
      },
    }
  );
  const lookupsComplete = (data, updateValue, name) => {
    setValue(name, updateValue);
  };
  const onSubmit = (data) => {
    const zones = []
    var zone

    for (const key in data) {
      if (data[key] === "") {
        if (key === "integrationUrl") {
          data[key] = null
        }
      }
    }

    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    for (const key in data) {

      if (key.includes('zone')) {
        zone = {
          parent: key.includes('sub') ? false : true,
          remoteId: data[key],
          localId: parseInt(key.match(/\d+/)[0], 10)
        }
        zones.push(zone)
        delete data[key];
      }


    }
    zones.push(...subZoneList)
    const services = serviceList
      ? serviceList.map((i) => {
        const services = {
          localId: i.service.id,
          remoteId: i.remoteId,
        };
        return services;
      }) : [];

    const reason = cancellationReasonList
      ? cancellationReasonList.map((i) => {
        const reason = {
          localId: i.service.id,
          remoteId: i.remoteId,
        };
        return reason;
      }) : [];
    saveIntegration({
      variables: {
        input: {
          ...data,
          ...(updateDeliveryAgent && {
          }),
          services: services,
          cancellationReasons: reason,
          zones: zones,
        },
      },
    })
      .then((data) => {
        pushUrl(
          props,
          `/admin/delivery-agents/${data?.data?.saveIntegration?.deliveryAgent.id}`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };





  const serviceForm = (
    <ServiceForm
      handleSubmit={serviceHandleSubmit}
      onSubmitService={onSubmitService}
      control={serviceControl}
      errors={serviceErrors}
      setValue={serviceSetValue}
      watch={serviceWatch}
      parseData={parseData}
      onChangeNames={onChangeNames}
      setSelectedNames={setSelectedNames}
      serviceIndex={serviceIndex}
      serviceList={serviceList}
      serviceErrorMessage={serviceErrorMessage}
      handelCloseDialog={handelCloseDialog}
    />
  );

  const cancellationReasonsForm = (
    <CancellationReasons
      handleSubmit={cancellationReasonHandleSubmit}
      onSubmitService={onSubmitReason}
      control={cancellationReasonControl}
      errors={cancellationReasonErrors}
      setValue={cancellationReasonSetValue}
      watch={cancellationReasonWatch}
      parseData={parseData}
      onChangeNames={onChangeNamesReason}
      setSelectedNames={setReasonNames}
      serviceIndex={cancellationReasonIndex}
      serviceList={cancellationReasonList}
      serviceErrorMessage={reasonsErrorMessage}
      handelCloseDialog={handelCloseDialogReason}
    />
  );
  const subZoneListForm = (
    <SubZoneListForm
      handleSubmit={subZoneHandleSubmit}
      onSubmitService={onSubmitSubZone}
      control={subZoneControl}
      errors={subZoneErrors}
      setValue={subZoneSetValue}
      watch={subZoneWatch}
      parseData={parseData}
      serviceIndex={subZoneIndex}
      serviceList={subZoneList}
      handelCloseDialog={handelCloseDialogSubZone}
      subZoneId={subZoneId}
      open={dialogSubZone}
    />
  );


  const tapsArray = [
    {
      tabHead: t("service"),
      panel: <ServiceTable
        services={serviceList}
        addService={addServiceDialog}
        setServiceList={setServiceList}
        title={"service"}
      />,
    },
    {
      tabHead: t("cancellationReasons"),
      panel: <ServiceTable
        services={cancellationReasonList}
        addService={addServiceDialogReason}
        setServiceList={setCancellationReasonList}
        title={"cancellationReasons"}
      />,
    },
    {
      tabHead: t("zonesList"),
      panel: <IntegrationZoneTable
        variables={{ input: { parentId: null } }}
        title={"zonesList"}
        query={LIST_ZONES_DROPDOWN.query}
        parseData={(data) => parseData(data)}
        addService={addServiceDialogSubZone}
        control={control}
        errors={errors}
      />,
    },
  ];
  const body = (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <TitleAppBar path={props.match.path} />
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="end" xs={12}>
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
            </Box>
            <Box>
              <MuiSwitch
                edge="end"
                name="useShipmentCode"
                label={t("enterShipmentCode")}
                control={control}
              />
            </Box>
            <Box>
              <MuiSwitch
                edge="end"
                name="useSenderData"
                label={t("enterSenderData")}
                control={control}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"token"}
              label={t("token")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"integrationUrl"}
              label={t("integrationUrl")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <EnumDropdown
              name={"typeCode"}
              label={t("type")}
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              variables={
                { name: "IntegrationAdapterTypeCode" }
              }
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  updateDeliveryAgent.deliveryAgent.integration.typeCode
                  ,
                  "typeCode"
                )
              }
            />
          </Grid>

        </Paper>

        {!saveIntegrationLoading && (
          <CustomTab tapDetails={tapsArray} />
        )}


        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveIntegrationLoading}>
            {saveIntegrationLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </Root>
  );

  return saveIntegrationLoading || DelivaryAgentLoadingId ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : (
      <>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={dialog}
          onClose={handelCloseDialog}
        >
          {serviceForm}
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={dialogCancel}
          onClose={handelCloseDialogReason}
        >
          {cancellationReasonsForm}
        </Dialog>

        {subZoneListForm}

        {body}
      </>
    );
}

export default DeliveryAgentIntegrationForm;

function ServiceForm({
  handleSubmit,
  onSubmitService,
  control,
  errors,
  parseData,
  onChangeNames,
  serviceIndex,
  serviceList,
  setValue,
  setSelectedNames,
  watch,
  serviceErrorMessage,
  handelCloseDialog,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    service: null,
  });

  useEffect(() => {
    if (serviceIndex.update) {
      const update = serviceList[serviceIndex.index];
      setSelectedNames({ service: update?.service?.username ?? update?.service?.name });
      setValue("remoteId", update?.remoteId)
      setAutocompleteValues({
        service: { id: update?.service?.id, name: update?.service?.username ?? update?.service?.name },
      });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<form onSubmit={handleSubmit(onSubmitService)}>
    <DialogTitle>
      <Typography color={"text.primary"} >{t("service")}</Typography>
    </DialogTitle>
    <DialogContent>
      <Grid
        container
        justifyContent="flex-start"
        aligns="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Grid xs={12} sm={12} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            rules={{ required: t("fieldIsRequired") }}
            name={"serviceId"}
            label={t("service")}
            parseData={(data) => parseData(data)}
            query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
            onChangeValue={(e) => {
              onChangeNames(e, "service");
            }}
            hideCode={true}
            defaultValue={autocompleteValues.service}
          />
        </Grid>
        <Grid xs={12} sm={12} aligns="flex-start">
          <ControlMUItextField
            rules={{ required: t("fieldIsRequired") }}
            control={control}
            errors={errors}
            name={"remoteId"}
            label={t("remoteId")}
          />
        </Grid>
        {serviceErrorMessage && (
          <FormHelperText error>
            {t("thisSecretaryAlreadyExists")}
          </FormHelperText>
        )}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
      <Button type="submit">{t("confirm")}</Button>
    </DialogActions>
  </form>);

}



function CancellationReasons({
  handleSubmit,
  onSubmitService,
  control,
  errors,
  parseData,
  onChangeNames,
  serviceIndex,
  serviceList,
  setValue,
  setSelectedNames,
  watch,
  serviceErrorMessage,
  handelCloseDialog,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    cancelReason: null,
  });

  useEffect(() => {
    if (serviceIndex.update) {
      const update = serviceList[serviceIndex.index];
      setSelectedNames({ cancelReason: update?.service?.username ?? update?.service?.name });
      setValue("remoteId", update?.remoteId)
      setAutocompleteValues({
        cancelReason: { id: update?.service?.id, name: update?.service?.username ?? update?.service?.name },
      });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<form onSubmit={handleSubmit(onSubmitService)}>
    <DialogTitle>
      <Typography color={"text.primary"} >{t("cancellationReasons")}</Typography>
    </DialogTitle>
    <DialogContent>
      <Grid
        container
        justifyContent="flex-start"
        aligns="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Grid xs={12} sm={12} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            rules={{ required: t("fieldIsRequired") }}
            name={"cancellationReasonId"}
            label={t("cancellationReasons")}
            parseData={(data) => parseData(data)}
            query={LIST_CANCELLATION_REASONS_DROPDOWN.query}
            onChangeValue={(e) => {
              onChangeNames(e, "cancelReason");
            }}
            hideCode={true}
            defaultValue={autocompleteValues.cancelReason}
          />
        </Grid>
        <Grid xs={12} sm={12} aligns="flex-start">
          <ControlMUItextField
            rules={{ required: t("fieldIsRequired") }}
            control={control}
            errors={errors}
            name={"remoteId"}
            label={t("remoteId")}
          />
        </Grid>
        {serviceErrorMessage && (
          <FormHelperText error>
            {t("thisSecretaryAlreadyExists")}
          </FormHelperText>
        )}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
      <Button type="submit">{t("confirm")}</Button>
    </DialogActions>
  </form>);

}

function SubZoneListForm({
  handleSubmit,
  onSubmitService,
  control,
  errors,
  parseData,
  serviceIndex,
  serviceList,
  setValue,
  setSelectedNames,
  watch,
  handelCloseDialog,
  subZoneId,
  open,
  async
}) {
  const { t } = useTranslation();
  const [setAutocompleteValues] = useState({
    cancelReason: null,
  });
  const { data: subZone, loading: loadingSubZone } = useQuery(
    gql`
    ${LIST_ZONES_DROPDOWN.query}
  `,
    {
      // nextFetchPolicy: "no-cache",
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      skip: Boolean(!subZoneId),
      variables: {
        ...(!async && subZoneId && { input: { parentId: subZoneId } }),
      },
      // onCompleted: (data) => {
      //   onCompleted && onCompleted(data);
      // },
    }
  );
  const SubZoneName = subZone && Object.keys(subZone)?.[0]
  const subZoneData = parseData(subZone?.[SubZoneName]);

  useEffect(() => {
    if (serviceIndex.update) {
      const update = serviceList[serviceIndex.index];
      setSelectedNames({ cancelReason: update?.service?.username ?? update?.service?.name });
      setValue("remoteId", update?.remoteId)
      setAutocompleteValues({
        cancelReason: { id: update?.service?.id, name: update?.service?.username ?? update?.service?.name },
      });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog
      onClose={handelCloseDialog}
      open={open}
      maxWidth="sm"
      fullWidth={true}
      component={"form"}
      onSubmit={handleSubmit(onSubmitService)}
    >
      <DialogTitle color="inherit" id="alert-dialog-title">
        {t("zonesAndSubzones")}
      </DialogTitle>
      {loadingSubZone ? <StyledLoading container item justifyContent="center" className={classes.main}>
        <FullScreenLoading height={"100%"} />
      </StyledLoading > : <DialogContent>
        <Grid container spacing={2}>
          {subZoneData && subZoneData.map((row, index) => (
            <Grid xs={12} sm={6} key={index}>
              <Paper sx={{ p: 1, m: 0.5 }} elevation={6}>
                <Stack spacing={1}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <ListItemText id={row?.id} primary={row?.name} />

                  </Stack>
                  <Stack>
                    <ControlMUItextField
                      control={control}
                      name={`subzone${row.id}`}
                      label={t("remoteId")}
                    />
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          ))}
        </Grid>

      </DialogContent>}
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </Dialog>);

}
